import BigNumber from 'bignumber.js'
import { mapState, mapGetters, mapActions } from 'vuex'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

export default {
  components: {
    TxButton
  },
  props: {
    currentCurrency: Object
  },
  data () {
    return {
      isBusyHandleSignUp: false
    }
  },
  computed: {
    ...mapState({
      fiatCurrency: state => state.coins.fiatCurrency,
      fiatCurrenciesName: state => state.coins.fiatCurrenciesName
    }),
    ...mapGetters({
      getRate: 'coins/getRate'
    }),
    displayCoin () {
      return this.currentCurrency.symbol
    },
    currentRate () {
      return this.getRate(this.currentCurrency.rateServiceSymbol, this.fiatCurrency)
    },
    oneFiatToCoin () {
      return this.currentRate.isLoaded ? new BigNumber(1).div(this.currentRate.price).toFixed(6) : 0
    },
    priceRows () {
      return [0.01, 0.1, 1, 2, 3, 5, 10, 20, 50, 100, 1000].map(item => ({
        from: item,
        to: new BigNumber(item).times(this.currentRate.isLoaded ? this.currentRate.price : 0).toFixed(4)
      }))
    }
  },
  methods: {
    ...mapActions({
      authInitiate: 'auth/initiate'
    }),
    async handleSignUp () {
      await this.authInitiate({ mode: 'signup' })
    }
  }
}
