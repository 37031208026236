import { mapGetters, mapActions } from 'vuex'

import TxButton from '@/components/lib/TxButton/TxButton.vue'
import ScrollItemsPartial from '@/pages/lib/partials/ScrollItemsPartial/ScrollItemsPartial.vue'
import HowToItem from './HowToItem/HowToItem.vue'

export default {
  props: {
    currentCurrency: Object
  },
  components: {
    TxButton,
    HowToItem,
    ScrollItemsPartial
  },
  data () {
    return {
      isBusyHandleSignUp: false
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    }),
    currencyName () {
      return this.currentCurrency.name
    },
    prefix () {
      return this.currentCurrency.symbol.toLowerCase()
    },
    content () {
      if (this.currentPage === 'buy') {
        return {
          title: this.i18nTemplate('coinpageHowToBuyTemplate', { value: this.currencyName }),
          asH2: true
        }
      } else if (this.currentPage === 'sell') {
        return {
          title: this.i18nTemplate('coinpageHowToSellTemplate', { value: this.currencyName }),
          asH2: true
        }
      }
    },
    currentPage () {
      if (this.$route.name.includes('CoinBuy')) {
        return 'buy'
      } else if (this.$route.name.includes('CoinSell')) {
        return 'sell'
      }
    },
    oneItemText () {
      return this.i18nSection(`${this.currentPage}${this.prefix}howDoFirstStep`, `${this.prefix}howDoFirstStep`)
    },
    twoItemText () {
      return this.i18nSection(`${this.currentPage}${this.prefix}howDoSecondStep`, `${this.prefix}howDoSecondStep`)
    },
    threeItemText () {
      return this.i18nSection(`${this.currentPage}${this.prefix}howDoThirdStep`, `${this.prefix}howDoThirdStep`)
    },
    fourItemText () {
      return this.i18nSection(`${this.currentPage}${this.prefix}howDoFourStep`, `${this.prefix}howDoFourStep`)
    },
    howTo () {
      return [
        {
          num: 1,
          title: this.i18nSection('coinpageHowToItemTitleOne'),
          text: this.oneItemText
        },
        {
          num: 2,
          title: this.i18nSection('coinpageHowToItemTitleTwo'),
          text: this.twoItemText
        },
        {
          num: 3,
          title: this.i18nSection('coinpageHowToItemTitleThree'),
          text: this.threeItemText
        },
        {
          num: 4,
          title: this.i18nSection('coinpageHowToItemTitleFour'),
          text: this.fourItemText
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      authInitiate: 'auth/initiate'
    }),
    scrollItems () {
      const slider = document.querySelector('.slider')
      let isDown = false
      let startX
      let scrollLeft

      slider.addEventListener('mousedown', (e) => {
        isDown = true
        slider.classList.add('active')
        startX = e.pageX - slider.offsetLeft
        scrollLeft = slider.scrollLeft
      })
      slider.addEventListener('mouseleave', () => {
        isDown = false
        slider.classList.remove('active')
      })
      slider.addEventListener('mouseup', () => {
        isDown = false
        slider.classList.remove('active')
      })
      slider.addEventListener('mousemove', (e) => {
        if (!isDown) {
          return
        }
        e.preventDefault()
        const x = e.pageX - slider.offsetLeft
        const walk = (x - startX) * 3 // scroll-fast
        slider.scrollLeft = scrollLeft - walk
      })
    },
    async handleSignUp () {
      await this.authInitiate({ mode: 'signup' })
    }
  }
}
