import BigNumber from 'bignumber.js'
import format from 'date-fns/format'
import addMinutes from 'date-fns/addMinutes'
import capitalize from 'lodash/capitalize'
import { mapGetters, mapActions } from 'vuex'

import { MarketModel } from 'src/models'
import { formatNumber } from 'src/utils'

import Spinner from '@/components/lib/Spinner/Spinner.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

export default {
  components: {
    Spinner,
    TxButton
  },
  props: {
    currentMarket: MarketModel
  },
  computed: {
    ...mapGetters({
      getTrades: 'coins/getTrades'
    }),
    currentTrades () {
      if (this.currentMarket) {
        return this.getTrades(this.currentMarket.symbol)
      }
    }
  },
  methods: {
    ...mapActions({
      authInitiate: 'auth/initiate'
    }),
    capitalize,
    formatNumber,
    getFormattedDate (trade) {
      // TODO: public/trades return timestamp without timezone, but value is LocalDateTime for utc
      // so, we need to add timezoneoffset
      // remove it when rest will be changed
      const tradeDateTime = new Date(trade.timestamp)
      const localTradeDateTime = addMinutes(tradeDateTime, -tradeDateTime.getTimezoneOffset())
      return format(localTradeDateTime, 'dd/MM/yyyy')
    },
    getFormattedTime (trade) {
      const tradeDateTime = new Date(trade.timestamp)
      const localTradeDateTime = addMinutes(tradeDateTime, -tradeDateTime.getTimezoneOffset())
      return format(localTradeDateTime, 'hh:mm aa')
    },
    calculateTotal (trade) {
      return new BigNumber(trade.quantity).times(trade.price)
    },
    async handleSignUp () {
      await this.authInitiate({ mode: 'signup' })
    }
  }
}
