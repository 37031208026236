import { mapState, mapActions } from 'vuex'
import Spinner from '@/components/lib/Spinner/Spinner.vue'

import CoinPage from '../CoinPage/CoinPage.vue'
import NotFound from '../../NotFound/NotFound.vue'

export default {
  components: {
    Spinner
  },
  computed: {
    ...mapState({
      currencies: state => state.coins.currencies,
      isLoaded: state => state.coins.currencies.isLoaded,
      serverPrefetchExecuted: state => state.public.serverPrefetchExecuted
    }),
    coin () {
      return this.$route.params.coin
    },
    currency () {
      return this.currencies.value
        .find(coin => {
          return coin.symbol.toLowerCase() === this.coin.toLowerCase()
        })
    },
    coinPage () {
      return this.currency
        ? CoinPage
        : NotFound
    }
  },
  methods: {
    ...mapActions({
      loadCurrencies: 'coins/loadCurrencies',
      checkServerPrefetch: 'public/checkServerPrefetch'
    })
  },
  async mounted () {
    if (!this.serverPrefetchExecuted) {
      await this.loadCurrencies()
      this.checkServerPrefetch()
    }
  },
  async serverPrefetch () {
    await this.loadCurrencies()
    this.checkServerPrefetch()
  }
}
