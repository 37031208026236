import Spinner from '@/components/lib/Spinner/Spinner.vue'

export default {
  components: {
    Spinner
  },
  methods: {
    goToTrading () {
      this.openPrivateUrl('private')
    }
  }
}
