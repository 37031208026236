import BigNumber from 'bignumber.js'
import { mapState, mapActions, mapGetters } from 'vuex'

import TxButton from '@/components/lib/TxButton/TxButton.vue'
import SelectItem from '@/components/lib/SelectItem/SelectItem.vue'
import BuyCoinItem from './BuyCoinItem/BuyCoinItem.vue'

export default {
  components: {
    TxButton,
    SelectItem,
    BuyCoinItem
  },
  props: {
    currentCurrency: Object
  },
  data () {
    return {
      secondsLeft: 25,
      intervalId: null
    }
  },
  computed: {
    ...mapState({
      fiatCurrencies: state => state.coins.fiatCurrencies,
      fiatCurrency: state => state.coins.fiatCurrency
    }),
    ...mapGetters({
      getRate: 'coins/getRate'
    }),
    currentPage () {
      if (this.$route.name.includes('CoinBuy')) {
        return 'buy'
      } else if (this.$route.name.includes('CoinSell')) {
        return 'sell'
      }
    },
    sectionTitle () {
      if (this.currentPage === 'buy') {
        return this.i18nTemplate('coinpageBuyCoinSectionTitleTemplate', { value: this.currentCurrency.symbol }, { value: this.fiatCurrency })
      } else if (this.currentPage === 'sell') {
        return this.i18nTemplate('coinpageSellCoinSectionTitleTemplate', { value: this.currentCurrency.symbol }, { value: this.fiatCurrency })
      }
    },
    buyCoinItems () {
      return [
        {
          title: `200 ${this.fiatCurrency}`,
          fiatCurrency: this.fiatCurrency,
          displayCoin: this.currentCurrency.symbol,
          to: 200,
          from: this.currentRate.isLoaded
            ? new BigNumber(200).div(this.currentRate.price).toFixed(4)
            : 0,
          readonly: true
        },
        {
          title: `500 ${this.fiatCurrency}`,
          fiatCurrency: this.fiatCurrency,
          displayCoin: this.currentCurrency.symbol,
          to: 500,
          from: this.currentRate.isLoaded
            ? new BigNumber(500).div(this.currentRate.price).toFixed(4)
            : 0,
          readonly: true
        },
        {
          title: `1000 ${this.fiatCurrency}`,
          fiatCurrency: this.fiatCurrency,
          displayCoin: this.currentCurrency.symbol,
          to: 1000,
          from: this.currentRate.isLoaded
            ? new BigNumber(1000).div(this.currentRate.price).toFixed(4)
            : 0,
          readonly: true
        },
        {
          title: this.i18nSection('coinpageBuyCoinCustomTitle'),
          fiatCurrency: this.fiatCurrency,
          displayCoin: this.currentCurrency.symbol,
          to: null,
          from: null,
          readonly: false,
          placeholder: '0'
        }
      ]
    },
    displayCoin () {
      return this.currentCurrency.symbol
    },
    currentRate () {
      return this.getRate(this.currentCurrency.rateServiceSymbol.toUpperCase(), this.fiatCurrency)
    }
  },
  watch: {
    async fiatCurrency () {
      await this.loadRate({ from: this.currentCurrency.rateServiceSymbol.toUpperCase(), to: this.fiatCurrency })
    }
  },
  methods: {
    ...mapActions({
      changeCurrency: 'coins/changeCurrency',
      loadRate: 'coins/loadRate'
    }),
    selectCurrency (fiatCurrency) {
      this.changeCurrency({ fiatCurrency })
    }
  },
  mounted () {
    this.intervalId = setInterval(async () => {
      if (this.secondsLeft === 0) {
        this.secondsLeft = 25
        await this.loadRate({ from: this.currentCurrency.rateServiceSymbol.toUpperCase(), to: this.fiatCurrency })
      } else {
        this.secondsLeft -= 1
      }
    }, 1000)
  },
  beforeDestroy () {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }
}
