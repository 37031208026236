import BigNumber from 'bignumber.js'
import { mapGetters, mapActions } from 'vuex'

import BaseField from '@/components/lib/BaseField/BaseField.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

export default {
  props: {
    item: Object //
  },
  components: {
    BaseField,
    TxButton
  },
  data () {
    return {
      buttonIsBusy: false,
      from: this.item.from,
      to: this.item.to
    }
  },
  computed: {
    ...mapGetters({
      getRate: 'coins/getRate'
    }),
    currentRate () {
      return this.getRate(this.item.displayCoin, this.item.fiatCurrency)
    }
  },
  methods: {
    ...mapActions({
      authInitiate: 'auth/initiate'
    }),
    async handleSignUp () {
      this.buttonIsBusy = true
      await this.authInitiate({ mode: 'signup' })
      this.buttonIsBusy = false
    },
    handleInputTo (to) {
      this.to = to
      this.from = to && this.currentRate.isLoaded
        ? new BigNumber(to).div(this.currentRate.price).toFixed(4)
        : ''
    },
    handleInputFrom (from) {
      this.from = from
      this.to = from && this.currentRate.isLoaded
        ? new BigNumber(from).times(this.currentRate.price).toFixed(4)
        : ''
    }
  }
}
