import TxButton from '@/components/lib/TxButton/TxButton.vue'

export default {
  props: {
    currentCurrency: Object
  },
  components: {
    TxButton
  },
  computed: {
    prefix () {
      return this.currentCurrency.symbol.toLowerCase()
    },
    displayCoin () {
      return this.currentCurrency.symbol
    },
    currentPage () {
      if (this.$route.name.includes('CoinBuy')) {
        return 'buy'
      } else if (this.$route.name.includes('CoinSell')) {
        return 'sell'
      } else if (this.$route.name.includes('CoinTrade')) {
        return 'trade'
      } else if (this.$route.name.includes('CoinChart')) {
        return 'chart'
      }
    },
    sectionTitle () {
      return this.i18nSection(`${this.currentPage}ProsAndConsSectionTitle`, 'ProsAndConsSectionTitle')
    },
    sectionDescription () {
      return this.i18nSection(`${this.currentPage}ProsAndConsDescription`, 'ProsAndConsDescription')
    },
    pros () {
      return [
        {
          text: this.i18nSection(`${this.prefix}ProsAndConsPlusOne`, 'ProsAndConsPlusOne')
        },
        {
          text: this.i18nSection(`${this.prefix}ProsAndConsPlusTwo`, 'ProsAndConsPlusTwo')
        },
        {
          text: this.i18nSection(`${this.prefix}ProsAndConsPlusThree`, 'ProsAndConsPlusThree')
        },
        {
          text: this.i18nSection(`${this.prefix}ProsAndConsPlusFour`, 'ProsAndConsPlusFour')
        }
      ]
    },
    cons () {
      return [
        {
          text: this.i18nSection(`${this.prefix}ProsAndConsMinusOne`, 'ProsAndConsMinusOne')
        },
        {
          text: this.i18nSection(`${this.prefix}ProsAndConsMinusTwo`, 'ProsAndConsMinusTwo')
        },
        {
          text: this.i18nSection(`${this.prefix}ProsAndConsMinusThree`, 'ProsAndConsMinusThree')
        },
        {
          text: this.i18nSection(`${this.prefix}ProsAndConsMinusFour`, 'ProsAndConsMinusFour')
        }
      ]
    }
  },
  methods: {
    handleAbout (id) {
      const element = document.getElementById(id)
      element.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }
}
