
export default {
  props: {
    currentCurrency: Object
  },
  computed: {
    symbol () {
      return this.currentCurrency.symbol.toLowerCase()
    },
    currentPage () {
      if (this.$route.name.includes('CoinBuy')) {
        return 'buy'
      } else if (this.$route.name.includes('CoinSell')) {
        return 'sell'
      } else if (this.$route.name.includes('CoinTrade')) {
        return 'trade'
      } else if (this.$route.name.includes('CoinChart')) {
        return 'chart'
      }
    },
    sectionTitle () {
      return this.i18nSection(`${this.currentPage}${this.coin}SecondaryTitle`, `${this.symbol}SecondaryTitle`)
    },
    sectionDescription () {
      return this.i18nSection(`${this.currentPage}${this.coin}SecondaryDescription`, `${this.symbol}SecondaryDescription`)
    }
  }
}
