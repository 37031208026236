import { MarketModel } from 'src/models'

import { mapGetters, mapActions } from 'vuex'
import BigNumber from 'bignumber.js'
import capitalize from 'lodash/capitalize'

import Spinner from '@/components/lib/Spinner/Spinner.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

export default {
  components: {
    TxButton,
    Spinner
  },
  props: {
    currentMarket: MarketModel
  },
  data () {
    return {
      BigNumber,
      isBusyHandleSignUpFirst: false,
      isBusyHandleSignUpSecond: false
    }
  },
  computed: {
    ...mapGetters({
      getOrderbook: 'coins/getOrderbook'
    }),
    currentOrderbook () {
      if (this.currentMarket) {
        return this.getOrderbook(this.currentMarket.symbol)
      }
    },
    asks () {
      return this.currentOrderbook && this.currentOrderbook.ask.slice(0).reverse()
    },
    bids () {
      return this.currentOrderbook && this.currentOrderbook.bid
    }
  },
  methods: {
    ...mapActions({
      authInitiate: 'auth/initiate'
    }),
    capitalize,
    async handleSignUp () {
      await this.authInitiate({ mode: 'signup' })
    },
    signUpFirst () {
      this.isBusyHandleSignUpFirst = true
      this.handleSignUp()
    },
    signUpSecond () {
      this.isBusyHandleSignUpSecond = true
      this.handleSignUp()
    }
  }
}
