import { mapGetters, mapState } from 'vuex'
import BigNumber from 'bignumber.js'
import Spinner from '@/components/lib/Spinner/Spinner.vue'
import TxInlineSvg from '@/components/lib/TxInlineSvg/TxInlineSvg.vue'

const formatNumber = (num, toFormat) => {
  return new BigNumber(num).toFormat(toFormat)
}
export default {
  components: {
    Spinner,
    TxInlineSvg
  },
  props: {
    market: Object // MarketModel
  },
  computed: {
    ...mapState({
    }),
    ...mapGetters({
      getTickerBySymbol: 'tickers/getTickerBySymbol',
      getCurrencyBySymbol: 'currencies/getCurrencyBySymbol'
    }),
    ticker () {
      return this.getTickerBySymbol(this.market.symbol)
    },
    iconSrc () {
      const symbol = this.market.baseCurrency.toLowerCase()
      return this.getCurrencyBySymbol(symbol)
    },
    displayPriceChange () {
      if (!this.ticker || !this.ticker.change) {
        return '-'
      }

      const change = new BigNumber(this.ticker.change)

      let result = '0.0%'
      if (change.gt(0)) {
        result = `+${this.formatOrDash(this.ticker.changeAbs, 2)}%`
      } else if (change.lt(0)) {
        result = `-${this.formatOrDash(this.ticker.changeAbs, 2)}%`
      }

      return result
    },
    changeString () {
      if (!this.ticker || !this.ticker.change) {
        return 'none'
      }

      const change = new BigNumber(this.ticker.change)

      let result = 'none'

      if (change.gt(0)) {
        result = 'up'
      } else if (change.lt(0)) {
        result = 'down'
      }

      return result
    }
  },
  methods: {
    formatNumber,
    formatOrDash (value, n) {
      return value
        ? this.formatNumber(value, n)
        : ''
    },
    openTrading () {
      this.openPrivateUrl(`private/convert?from=${this.market.baseCurrency}&to=${this.market.quoteCurrency}`)
    }
  }
}
