import { mapGetters, mapState } from 'vuex'

import { CachedImageModel } from 'src/models'

import TxButton from '@/components/lib/TxButton/TxButton.vue'
import SelectItem from '@/components/lib/SelectItem/SelectItem.vue'
import TxInlineSvg from '@/components/lib/TxInlineSvg/TxInlineSvg.vue'

import PriceCalculator from './PriceCalculator/PriceCalculator.vue'

export default {
  props: {
    currentCurrency: Object,
    showCalculator: Boolean
  },
  components: {
    SelectItem,
    PriceCalculator,
    TxButton,
    TxInlineSvg
  },
  computed: {
    ...mapState({
      currencies: state => state.coins.currencies
    }),
    ...mapGetters({
      config: 'config/config'
    }),
    currentPage () {
      if (this.$route.name.includes('CoinBuy')) {
        return 'buy'
      } else if (this.$route.name.includes('CoinSell')) {
        return 'sell'
      } else if (this.$route.name.includes('CoinTrade')) {
        return 'trade'
      } else if (this.$route.name.includes('CoinChart')) {
        return 'chart'
      }
    },
    currencySymbol () {
      return this.currentCurrency.symbol.toLowerCase()
    },
    sectionTitle () {
      if (this.currentPage === 'buy') {
        return this.i18nTemplate('coinpageBuyTemplate', { value: this.currentCurrency.name })
      } else if (this.currentPage === 'sell') {
        return this.i18nTemplate('coinpageSellTemplate', { value: this.currentCurrency.name })
      } else if (this.currentPage === 'trade') {
        return this.i18nTemplate('coinpageTradeTemplate', { value: this.currentCurrency.name })
      } else if (this.currentPage === 'chart') {
        return this.i18nTemplate('coinpageChartTemplate', { value: this.currentCurrency.name })
      }
    },
    sectionDescription () {
      if (this.currentPage === 'buy') {
        return this.i18nSection(`${this.currencySymbol}BuyPageTopHeaderDescription`)
      } else if (this.currentPage === 'sell') {
        return this.i18nSection(`${this.currencySymbol}SellPageTopHeaderDescription`)
      } else if (this.currentPage === 'trade') {
        return this.i18nSection(`${this.currencySymbol}TradePageDescription`)
      } else if (this.currentPage === 'chart') {
        return this.i18nSection(`${this.currencySymbol}ChartPageDescription`)
      }
    }
  },
  methods: {
    changeCurrency (currency) {
      this.$router.push({ name: this.$route.name, params: { coin: currency.symbol.toLowerCase() } })
    },
    getImageUrl (image) {
      const imageModel = CachedImageModel.fromServerModel(image, {
        baseUrl: this.config.publicService.imageUrl
      })
      return imageModel.url
    }
  }
}
