import { mapActions, mapState } from 'vuex'
import { formatNumber } from 'accounting'
import { MarketModel } from 'src/models'

import Spinner from '@/components/lib/Spinner/Spinner.vue'
import PriceChartItem from './PriceChartItem/PriceChartItem.vue'
import SelectItem from '@/components/lib/SelectItem/SelectItem.vue'
import PublicTradingView from '@/components/lib/PublicTradingView/PublicTradingView.vue'

export default {
  props: {
    currentCurrency: Object,
    currentPage: String,
    currentMarket: MarketModel
  },
  components: {
    Spinner,
    PriceChartItem,
    SelectItem,
    PublicTradingView
  },
  computed: {
    ...mapState({
      markets: state => state.coins.markets,
      fiatCurrency: state => state.coins.fiatCurrency,
      publicCoinsInfo: state => state.coinsInfo.list
    }),
    coinInfo () {
      return this.publicCoinsInfo.value.find(coin => {
        return coin.symbol.toLowerCase() === this.currencySymbol.toLowerCase()
      })
    },
    currentMarketName () {
      if (this.currentMarket) {
        return this.currentMarket.name
      }
    },
    currencySymbol () {
      return this.currentCurrency.symbol
    },
    priceChartSectionDescription () {
      return this.i18nSection(`${this.currencySymbol}CoinpagePriceChartDescription`)
    },
    priceChartItem () {
      return [
        {
          firstLineText: this.i18nTemplate('coinpagePriceChartItemOneTemplate', { value: `${this.formatOrDash(this.coinInfo.info.price.aud, 2)}` })
        },
        {
          firstLineText: this.i18nTemplate('coinpagePriceChartItemTwoTemplate', { value: `${this.formatOrDash(this.coinInfo.info.tradingVolume.aud, 2)}` })
        },
        {
          firstLineText: this.i18nTemplate('coinpagePriceChartItemThreeTemplate', { value: `${this.formatOrDash(this.coinInfo.info.marketCap.aud, 2)}` })
        }
      ]
    },
    filteredMarkets () {
      return this.markets.value.filter(market => {
        const isCurrentMarket =
          market.baseCurrency.toLowerCase() === this.currencySymbol.toLowerCase() ||
          market.quoteCurrency.toLowerCase() === this.currencySymbol.toLowerCase()
        return isCurrentMarket && !market.locked
      })
    },
    chartMarket () {
      const audtMarket = this.markets.value.find(market => {
        return ((market.baseCurrency.toUpperCase() === this.currencySymbol.toLowerCase() && market.quoteCurrency.toLowerCase() === 'AUDT') ||
          (market.baseCurrency.toUpperCase() === 'AUDT' && market.quoteCurrency.toLowerCase() === this.currencySymbol.toLowerCase())) &&
          !market.locked
      })
      if (audtMarket) {
        return audtMarket
      }
      const markets = this.filteredMarkets
      if (markets.length > 0) {
        return markets[0]
      }
      return null
    }
  },
  methods: {
    formatNumber,
    ...mapActions({
      setDefaultMarket: 'coins/setDefaultMarket',
      loadInfo: 'coinsInfo/loadInfo'
    }),
    changeMarket (market) {
      this.$emit('changeMarket', market)
    },
    formatOrDash (value, n) {
      return value
        ? this.formatNumber(value, n, ',')
        : '-'
    }
  },
  async mounted () {
    await this.setDefaultMarket({ coin: this.currencySymbol })

    const promises = []
    if (!(this.publicCoinsInfo.isLoading || this.publicCoinsInfo.isLoaded)) {
      promises.push(this.loadInfo())
    }
    if (promises.length > 0) {
      await Promise.all(promises)
    }
  }
}
