import { mapActions, mapGetters, mapState } from 'vuex'
import NavBar from '@/pages/lib/sections/NavBar/NavBar.vue'
import PublicFooter from '@/pages/lib/sections/PublicFooter/PublicFooter.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

import MarketsPlaceholderSection from '@/pages/lib/sections/MarketsPlaceholderSection/MarketsPlaceholderSection.vue'

import CalculateHeaderSection from './CalculateHeaderSection/CalculateHeaderSection.vue'
import CryptaTheBestSection from './CryptaTheBestSection/CryptaTheBestSection.vue'
import HowToSection from './HowToSection/HowToSection.vue'
import CurrentPriceSection from './CurrentPriceSection/CurrentPriceSection.vue'
import ProsAndConsSection from './ProsAndConsSection/ProsAndConsSection.vue'
import PriceChartSection from './PriceChartSection/PriceChartSection.vue'
import BuyCoinSection from './BuyCoinSection/BuyCoinSection.vue'
import OpenOrdersSection from './OpenOrdersSection/OpenOrdersSection.vue'
import RecentlyCompletedOrdersSection from './RecentlyCompletedOrdersSection/RecentlyCompletedOrdersSection.vue'
import SeoMarketsSection from './SeoMarketsSection/SeoMarketsSection.vue'
import SecondaryDescriptionSection from './SecondaryDescriptionSection/SecondaryDescriptionSection.vue'

export default {
  components: {
    NavBar,
    PublicFooter,
    TxButton,
    MarketsPlaceholderSection,
    CalculateHeaderSection,
    CryptaTheBestSection,
    HowToSection,
    CurrentPriceSection,
    ProsAndConsSection,
    PriceChartSection,
    BuyCoinSection,
    OpenOrdersSection,
    RecentlyCompletedOrdersSection,
    SeoMarketsSection,
    SecondaryDescriptionSection
  },
  computed: {
    ...mapGetters({
      config: 'config/config',
      getCurrencyBySymbol: 'coins/getCurrencyBySymbol'
    }),
    ...mapState({
      currencies: state => state.coins.currencies.value,
      markets: state => state.coins.markets,
      currentMarket: state => state.coins.currentMarket
    }),
    isClientProcess () {
      return process.client
    },
    coinSymbol () {
      return this.$route.params.coin
    },
    currentCurrency () {
      return this.getCurrencyBySymbol(this.coinSymbol)
    },
    currentPage () {
      if (this.$route.name.includes('CoinBuy')) {
        return 'buy'
      } else if (this.$route.name.includes('CoinSell')) {
        return 'sell'
      } else if (this.$route.name.includes('CoinTrade')) {
        return 'trade'
      } else if (this.$route.name.includes('CoinChart')) {
        return 'chart'
      }
    }
  },
  watch: {
    markets: {
      async handler () {
        await this.setDefaultMarket({ coin: this.coinSymbol })
      },
      immediate: true
    },
    async coinSymbol () {
      await this.setDefaultMarket({ coin: this.coinSymbol })
      this.loadInitialRate({ rateServiceSymbol: this.currentCurrency.rateServiceSymbol })
    },
    async currentMarket () {
      await this.loadTradeData({ market: this.currentMarket })
    }
  },
  methods: {
    ...mapActions({
      setDefaultMarket: 'coins/setDefaultMarket',
      changeMarket: 'coins/changeMarket',
      loadTradeData: 'coins/loadTradeData',
      loadInitialData: 'coins/loadInitialData',
      loadInitialRate: 'coins/loadInitialRate'
    }),
    handleChangeMarket (market) {
      this.changeMarket({ market })
    },
    createLocalizedLink (url) {
      if (url.startsWith('/private')) {
        return url
      }
      return this.getLanguageUrlPathPrefix() + url
    }
  },
  async serverPrefetch () {
    await this.loadInitialData({
      rateServiceSymbol: this.currentCurrency.rateServiceSymbol
    })
  },
  async updated () {
    if (this.currentPage === 'trade') {
      await this.setDefaultMarket({ coin: this.coinSymbol })
      await this.loadTradeData({ market: this.currentMarket })
    }
  },
  async mounted () {
    if (this.currentPage === 'trade') {
      await this.setDefaultMarket({ coin: this.coinSymbol })
      await this.loadTradeData({ market: this.currentMarket })
    }
  }
}
