import { mapState, mapActions, mapGetters } from 'vuex'
import MarketItem from './MarketItem/MarketItem.vue'

export default {
  props: {
    currentCurrency: Object
  },
  components: {
    MarketItem
  },
  computed: {
    ...mapGetters({
      getMarketBySymbol: 'coins/getMarketBySymbol'
    }),
    ...mapState({
      passport: state => state.passport?.user,
      markets: state => state.coins.markets
    }),
    seoMarkets () {
      if (!this.markets.isLoaded) {
        return []
      }
      return this.markets.value
        .filter(market => {
          const isCurrentMarket = market.baseCurrency.toLowerCase() === this.currentCurrency.symbol.toLowerCase() ||
            market.quoteCurrency.toLowerCase() === this.currentCurrency.symbol.toLowerCase()
          return isCurrentMarket && !market.locked
        })
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .slice(0, 3)
    }
  },
  methods: {
    ...mapActions({
      loadTickers: 'tickers/loadTickers'
    }),
    goToTrading () {
      if (this.passport) {
        this.openPrivateUrl('private/convert')
      } else {
        this.openPrivateUrl(`private/trading/${this.markets[0].market.symbol}`)
      }
    }
  },
  mounted () {
    this.loadTickers({ markets: this.markets })
  }
}
