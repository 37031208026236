import VueTypes from 'vue-types'

export default {
  props: {
    disabled: Boolean,
    icon: String,
    size: [Number, String],
    href: String,
    theme: VueTypes.oneOf(['normal', 'green', 'red', 'purple', 'dark', 'light']).def('normal')
  }
}
