import uniqid from 'uniqid'
// import VueTypes from 'vue-types'
// import { PLASMA } from 'src/remotes'
import Spinner from '@/components/lib/Spinner/Spinner.vue'

export default {
  components: {
    Spinner
  },
  props: {
    symbol: String
  },
  data () {
    return {
      containerId: `tradingView-${uniqid()}`,
      isReady: false
    }
  },
  mounted () {
    if (window.TradingView && window.TradingView.widget) {
      this.createWidget()
    } else {
      const tvWidget = document.createElement('script')
      tvWidget.setAttribute('src', 'https://s3.tradingview.com/tv.js')
      tvWidget.onload = () => {
        this.createWidget()
      }
      document.head.appendChild(tvWidget)
    }
  },
  methods: {
    createWidget () {
      this.isReady = true

      /* eslint-disable */
      new TradingView.widget({
        width: '100%',
        height: '100%',
        symbol: this.symbol,
        interval: "D",
        timezone: "Etc/UTC",
        'theme': "light",
        style: "1",
        locale: "en",
        toolbar_bg: "#f1f3f6",
        enable_publishing: false,
        allow_symbol_change: true,
        container_id: this.containerId
      })
    }
  }
}
