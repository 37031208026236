import WithBackgroundImagePartial from '@/pages/lib/partials/WithBackgroundImagePartial/WithBackgroundImagePartial.vue'

export default {
  props: {
    currentCurrency: Object
  },
  components: {
    WithBackgroundImagePartial
  },
  computed: {
    symbol () {
      return this.currentCurrency.symbol.toLowerCase()
    },
    currentPage () {
      if (this.$route.name.includes('CoinBuy')) {
        return 'buy'
      } else if (this.$route.name.includes('CoinSell')) {
        return 'sell'
      } else if (this.$route.name.includes('CoinTrade')) {
        return 'trade'
      } else if (this.$route.name.includes('CoinChart')) {
        return 'chart'
      }
    },
    items () {
      return [
        {
          itemTitle: this.i18nTemplate('coinpageCryptaTheBestTemplate', { value: this.currentCurrency.name }),
          itemText: this.i18nSection(`${this.currentPage}${this.symbol}AboutCoinDescription`, `${this.symbol}AboutCoinDescription`),
          titleAsH2: true
        }
      ]
    }
  }
}
