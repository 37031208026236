import BigNumber from 'bignumber.js'
import { mapState, mapGetters, mapActions } from 'vuex'

import BaseField from '@/components/lib/BaseField/BaseField.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'
import ActionIcon from '@/components/lib/ActionIcon/ActionIcon.vue'

export default {
  components: {
    BaseField,
    TxButton,
    ActionIcon
  },
  props: {
    symbol: String
  },
  data () {
    return {
      from: '',
      to: '',
      isBusyHandleSignUp: false
    }
  },
  computed: {
    ...mapState({
      fiatCurrency: state => state.coins.fiatCurrency
    }),
    ...mapGetters({
      getRate: 'coins/getRate',
      config: 'config/config'
    }),
    displayCoin () {
      return this.symbol
    },
    currentRate () {
      return this.getRate(this.displayCoin, this.fiatCurrency)
    }
  },
  methods: {
    ...mapActions({
      openModal: 'modals/open',
      authInitiate: 'auth/initiate'
    }),
    fromMinus () {
      if (this.from >= 1) {
        this.from = this.from - 1
        this.handleInputFrom(this.from)
      }
    },
    fromPlus () {
      this.from = Number(this.from) + 1
      this.handleInputFrom(this.from)
    },
    toMinus () {
      if (this.to >= 1) {
        this.to = this.to - 1
        this.handleInputTo(this.to)
      }
    },
    toPlus () {
      this.to = Number(this.to) + 1
      this.handleInputTo(this.to)
    },
    handleInputTo (to) {
      this.to = to
      this.from = this.currentRate.isLoaded
        ? new BigNumber(to).div(this.currentRate.price).toFixed(6)
        : ''
    },
    handleInputFrom (from) {
      this.from = from
      this.to = this.currentRate.isLoaded
        ? new BigNumber(from).times(this.currentRate.price).toFixed(6)
        : ''
    },
    async handleSignUp () {
      this.isBusyHandleSignUp = true
      await this.authInitiate({ mode: 'signup' })
    },
    handleBuyCoin () {
      this.handleSignUp()
    }
  }
}
